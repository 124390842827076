import React from 'react'
import '../components/ContactForm.css'

import Layout from '../components/Layout'

const ContactPage = () => (
  <Layout
    pageTitle="Contact"
  >
    <main className="Content">
      <div className="wrap">
        <h1>Contact</h1>
        <p>Want to chat? Shoot us an email: <strong>team@thedangercrew.com!</strong></p>
        <p>We try to respond as soon as possible!</p>
      </div>
    </main>
  </Layout>
);

export default ContactPage
